import { clsx, type ClassValue } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'
import { theme } from '../../theme'

export const bubbleAnimation = tv({
  base: 'animate-in zoom-in-50 ease-in-bubble [animation-duration:var(--duration,0)]',
  variants: {
    from: {
      left: 'origin-bottom-left',
      right: 'origin-bottom-right',
    },
  },
})

const customMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': Object.keys(theme?.fontSize!).map(key => `text-${key}`),
    },
  },
})

export function cn(...inputs: ClassValue[]) {
  return customMerge(clsx(inputs))
}
