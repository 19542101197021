'use client'

import { cn } from '@paladise/ui/lib/utils'
import { Slot } from '@radix-ui/react-slot'
import * as React from 'react'
import { tv, type VariantProps } from 'tailwind-variants'

const buttonVariants = tv({
  base: 'text-md focus-visible:ring-ring inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-full font-semibold transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
  variants: {
    variant: {
      primary: 'bg-primary-p1 text-primary-on-primary',
      'primary-p2': 'bg-primary-p2 text-primary-p1 hover:bg-primary-p2-hover',
      secondary: 'bg-secondary-p2 hover:bg-secondary-p2 text-primary-p1',
      third: 'bg-third-p1 text-third-on-third',
      gray: 'bg-background-2nd text-label-l2',
      base: 'border-label-l3 bg-background-1st hover:bg-background-1st border border-solid',
      'glass-options': 'bg-glass-special-options text-playseeGray-gray1',
      text: 'text-primary-p1',
      'label-l1': 'text-label-l1',
      'label-l2': 'text-label-l2',
    },
    size: {
      default: 'h-10 px-4 py-2',
      sm: 'h-9 px-3',
      lg: 'h-11 px-8',
      icon: 'h-10 w-10',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'default',
  },
})

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
  loadingIcon?: React.ReactNode
  loadingClassName?: string
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      loading,
      loadingIcon,
      loadingClassName,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {children}
        {loading
          ? (loadingIcon ?? (
              <span
                className={cn(
                  'i-ps-cell_loading text-label-still-l1 ml-1 animate-spin',
                  loadingClassName,
                )}
              />
            ))
          : null}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
